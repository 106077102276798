import { createRef } from "react";
import Col from 'react-bootstrap/Col';
import { stickersData } from "../stickers.data.ts";

export default function Stickers({ setStickers, canvasWidth, canvasHeight }) {
  const addSticker = ({ src, width, x, y }) => {
    setStickers((currentStickers) => [
      ...currentStickers,
      {
        width,
        x,
        y,
        src,
        resetButtonRef: createRef()
      }
    ]);
  };

  return (
    <>
      {stickersData.map((sticker, i) => {
        return (
          <Col key={i}>
            <button
              className="sticker-button"
              onMouseDown={() => {
                const stickerWidth = canvasWidth / sticker.width;
                addSticker({
                  src: sticker.url,
                  width: stickerWidth,
                  x: (canvasWidth - stickerWidth) / 2,
                  y: (canvasHeight - stickerWidth) / 2
                })
              }}
            >
              <img alt={sticker.alt} src={sticker.url} />
            </button>
          </Col>
        );
      })}
    </>
  );
}
