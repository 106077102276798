import { useCallback } from "react";
import { Image as KonvaImage, Layer, Stage } from "react-konva";
import { IndividualSticker } from "./IndividualSticker";

export default function Panel({dimensions, scale, stickers, background, setStickers, canvasRef}) {
  const resetAllButtons = useCallback(() => {
    stickers.forEach((sticker) => {
      if (sticker.resetButtonRef.current) {
        sticker.resetButtonRef.current();
      }
    });
  }, [stickers]);

  const handleCanvasClick = useCallback(
    (event) => {
      if (event.target.attrs.id === "backgroundImage") {
        resetAllButtons();
      }
    },
    [resetAllButtons]
  );

  return (
    <>
      <Stage
        width={dimensions.width}
        height={dimensions.height}
        onClick={handleCanvasClick}
        onTap={handleCanvasClick}
        style={{ width: dimensions.width }}
      >
        <Layer>
          <KonvaImage
            image={background}
            id="backgroundImage"
            width={dimensions.width}
            height={dimensions.height}
          />
          {stickers.map((sticker, i) => {
            return (
              <IndividualSticker
                onDelete={() => {
                  const newStickers = [...stickers];
                  newStickers.splice(i, 1);
                  setStickers(newStickers);
                }}
                onChange={newAttrs => {
                  const stickerList = [...stickers];
                  stickerList[i] = newAttrs;
                  setStickers(stickerList);
                }}
                key={i}
                stickerProps={sticker}
              />
            );
          })}
        </Layer>
      </Stage>
      <Stage width={dimensions.imgWidth} height={dimensions.imgHeight} ref={canvasRef} style={{ display: 'none' }}>
        <Layer>
          <KonvaImage
            image={background}
            width={dimensions.imgWidth}
            height={dimensions.imgHeight}
          />
        </Layer>
        <Layer width={dimensions.imgWidth} height={dimensions.imgHeight} scaleX={scale} scaleY={scale}>
          {stickers.map((sticker, i) => {
            return (
              <IndividualSticker
                key={'hiden-' + i}
                stickerProps={sticker}
              />
            );
          })}
        </Layer>
      </Stage>
    </>
  );
}
