import Container from 'react-bootstrap/Container';
import ShareButton from "./ShareButton"
import FilePicker from "./FilePicker";

export default function Toolbar({ variant, size, show = true, text, canvasRef, onFilePickerOpen, onFileSelect, onShare }) {
  return (
    <Container className="d-flex justify-content-between">
      {show &&
      <>
        <FilePicker onChange={onFileSelect} onClick={onFilePickerOpen} />
        <ShareButton
          canvasRef={canvasRef}
          onClick={onShare}
          variant={variant}
          size={size}
          text={text}
        />
      </>
      }
    </Container>
  );
}
