export const stickersData = [
  {
    url: "stickers/campana.png",
    width: 5,
    alt: "Memoria Sí"
  },
  {
    url: "stickers/panuelocorazon.png",
    width: 5,
    alt: "Pañuelo corazón"
  },
  {
    url: "stickers/panuelocorazon-agua.png",
    width: 5,
    alt: "Pañuelo corazón"
  },
  {
    url: "stickers/panuelocorazon-amarillo.png",
    width: 5,
    alt: "Pañuelo corazón"
  },
  {
    url: "stickers/panuelocorazon-naranja.png",
    width: 5,
    alt: "Pañuelo corazón"
  },
  {
    url: "stickers/panuelocorazon-rosa.png",
    width: 5,
    alt: "Pañuelo corazón"
  },
  {
    url: "stickers/panuelocorazon-verde.png",
    width: 5,
    alt: "Pañuelo corazón"
  },
  {
    url: "stickers/panuelocorazon-violeta.png",
    width: 5,
    alt: "Pañuelo corazón"
  },
  {
    url: "stickers/memoriasi.png",
    width: 4,
    alt: "Memoria sí"
  },
  {
    url: "stickers/nuncamas-blanco.png",
    width: 5,
    alt: "Nunca Más"
  },
  {
    url: "stickers/nuncamas-rojo.png",
    width: 5,
    alt: "Nunca Más"
  },
  {
    url: "stickers/pancarta.png",
    width: 5,
    alt: "30.000 presentes"
  },
  {
    url: "stickers/panuelo30mil.png",
    width: 5,
    alt: "Pañuelo 30.000"
  },
  {
    url: "stickers/son30k.png",
    width: 5,
    alt: "Son 30.000"
  },
  {
    url: "stickers/juicioycastigo.png",
    width: 5,
    alt: "Juicio y castigo"
  }






];
