import Button from 'react-bootstrap/Button';
import { isMobile } from 'react-device-detect';
import { MdDownload as DownloadIcon, MdShare as ShareIcon } from "react-icons/md";

export default function ShareButton({canvasRef, onClick, variant, size, text, ...props}) {

  const exportImage = async (filename) => {
    var canvas = canvasRef.current;

    const data = canvas.toDataURL();
    const blob = await (await fetch(data)).blob();
    return new File([blob], filename, { type: blob.type });
  }

  const handleShareImage = async () => {
    onClick && onClick();
    const file = await exportImage('memoria-si.png');

    if (navigator.share) {
      try {
        navigator.clipboard.writeText(text);

        await navigator.share({
          title: text,
          text: text,
          files: [file],
        });
      }
      catch (e) {
        // Ignoro la excepcion lanzada cuando el suaurio cancela la accion de compartir.
      }
    }
  };

  const handleDownloadImage = async () => {
    onClick && onClick();
    const file = await exportImage('memoria-si.png');
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = 'memoria-si.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {isMobile &&
        <Button variant={variant} size={size} onClick={handleShareImage} className="share">
          <ShareIcon />
        </Button>
      }
      <Button variant={variant} size={size} onClick={handleDownloadImage} className="download">
        <DownloadIcon />
      </Button>
    </>
  );
}


