import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { MdPhotoCamera as CameraIcon } from "react-icons/md";

export default function FilePicker({onChange, ...props}) {
  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    noClick: true,
    noDrag: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg']
    },
    onDrop: (acceptedFiles) => {
      const img = new window.Image();
      img.src = URL.createObjectURL(acceptedFiles[0]);
      onChange(img);
    },
  });

  return (
    <Container>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <Button
          variant="secondary"
          size="lg"
          onClick={open}
        >
          <CameraIcon /> Subí tu foto
        </Button>
      </div>
    </Container>
  );
}
