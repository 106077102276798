import useImage from "use-image";
import React, { useState, useEffect, useRef } from "react";
import { Image as KonvaImage, Transformer } from "react-konva";

export const IndividualSticker = ({ stickerProps, onDelete, onChange }) => {
  const imageRef = useRef(null);
  const transformerRef = useRef(null);
  const deleteButtonRef = useRef(null);

  const [stickerImage] = useImage(stickerProps.src);
  const [deleteImage] = useImage("cancel.svg");

  const [isChanging, setIsChanging] = useState(false);
  const [isSelected, setSelected] = useState(false);

  stickerProps.resetButtonRef.current = () => {
    setSelected(false);
  };

  const stickerHeight = stickerImage
    ? (stickerProps.width * stickerImage.height) / stickerImage.width
    : 0;

  const positionDeleteButton = () => {
    const transform = imageRef.current.getTransform().copy();
    const attrs = deleteButtonRef.current.getAttrs();
    const newAttrs = transform.decompose();

    newAttrs.x -= attrs.width / 2;
    newAttrs.y -= attrs.height / 2;

    deleteButtonRef.current.setAttrs(newAttrs);
  }

  useEffect(() => {
    if (isSelected && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
      positionDeleteButton();
    }
  }, [isSelected]);

  return (
    <>
      <KonvaImage
        ref={imageRef}
        draggable
        {...stickerProps}
        height={stickerHeight}
        image={stickerImage}
        onClick={() => setSelected(!isSelected)}
        onTap={() => setSelected(!isSelected)}
        onDragStart={() => setIsChanging(true)}
        onDragEnd={(event) => {
          setIsChanging(false);
          setSelected(true);
          positionDeleteButton();

          const transform = imageRef.current.getTransform().copy();
          const newAttrs = transform.decompose();

          onChange({
            ...stickerProps,
            ...newAttrs,
            x: event.target.x(),
            y: event.target.y()
          });
        }}
        onTransformStart={() => { setIsChanging(true); }}
        onTransformEnd={(event) => {
          setIsChanging(false);
          positionDeleteButton();

          const node = imageRef.current;
          const transform = node.getTransform().copy();
          const newAttrs = transform.decompose();

          onChange({
            ...stickerProps,
            ...newAttrs,
          });
        }}
      />

      {isSelected && (
        <>
          <Transformer
            ref={transformerRef}
            boundBoxFunc={(oldBox, newBox) => {
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
            enabledAnchors={['bottom-right']}
          />
          {!isChanging && (
            <KonvaImage
              ref={deleteButtonRef}
              onTouchStart={onDelete}
              onClick={onDelete}
              image={deleteImage}
              width={20}
              height={20}
              x={stickerProps.x - 10}
              y={stickerProps.y - 10}
              offsetX={-stickerProps.width}
            />
          )}
        </>
      )}
    </>
  );
};
