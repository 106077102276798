import React, { useEffect, useRef, useState } from "react";
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import "./App.scss";
import Panel from "./components/Panel"
import Stickers from "./components/Stickers"
import Toolbar from "./components/Toolbar";

export default function App() {
  const [stickers, setStickers] = useState([]);
  const [background, setBackground] = useState(new window.Image());
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0
  });
  const [imgDimensions, setImgDimensions] = useState({
    width: background.width,
    height: background.height
  });
  background.onload = () => {
    if (background.width > 0) {
      const { width, height } = background;
      const max = 1920;

      let imgScale = 1;
      if (width > height && width > max) {
        imgScale = max / width;
      }
      else if (height > width && height > max) {
        imgScale = max / height;
      }

      setImgDimensions({
        width: width * imgScale,
        height: height * imgScale
      });

      setStickers([]);
    }
  };

  const [scale, setScale] = useState(1);

  const [dimensions, setDimensions] = useState({
    width: 600,
    height: 400
  });

  useEffect(() => {
    const scaleX = containerDimensions.width / imgDimensions.width;
    const scaleY = containerDimensions.height / imgDimensions.height;
    const scale = Math.min(scaleX, scaleY);
    setScale(1 / scale);
    setDimensions({
      width: imgDimensions.width * scale,
      height: imgDimensions.height * scale
    });
  }, [imgDimensions, containerDimensions]);

  const panelRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const img = new window.Image();
    img.src = "/inicial.png";
    setBackground(img);

    if (panelRef.current?.offsetHeight && panelRef.current?.offsetWidth) {
      setContainerDimensions({
        width: panelRef.current.offsetWidth,
        height: panelRef.current.offsetHeight
      })
    }
  }, []);

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    panelRef.current.addEventListener('contextmenu', handleContextmenu)
    return () => {
      panelRef.current.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  const text = "#MemoriaSí #SumemosPañuelos #48AñosDelGolpeGenocida. 🤍💜 Este 24 de marzo nos encontramos en las plazas de todo el país: digamos sí a la memoria y a todas las luchas por más derechos.";

  return (
    <Container fluid as="main" className="mx-auto">
      <Row>
        <Col xs={{ span: 12 }}>
          <h1><img width={210} src="memoria-si.png" alt="Memoria Sí" title="Memoria Sí" /></h1>
        </Col>
        <Col xs={{ span: 8, offset: 2 }}>
          <p className="slogan">
            Subí tu foto, intervenila y compartila en redes para sumarte a la campaña.
            <br />
            <b>#MemoriaSí #SumemosPañuelos #48AñosDelGolpeGenocida</b>
          </p>
        </Col>
      </Row>
      <Toolbar
        variant="secondary"
        size="lg"
        canvasRef={canvasRef}
        onFileSelect={(img) => setBackground(img)}
        text={text}
      />
      <Row>
        <Col ref={panelRef} className="panel d-flex justify-content-evenly">
          <Panel
            dimensions={{
              width: dimensions.width,
              height: dimensions.height,
              imgWidth: imgDimensions.width,
              imgHeight: imgDimensions.height
            }}
            scale={scale}
            background={background}
            stickers={stickers}
            setStickers={setStickers}
            canvasRef={canvasRef}
          />
        </Col>
      </Row>
      <Container fluid className="stickers">
        <h3>Stickers</h3>
        <p className="description">
          Agregá stickers a la imagen haciendo click, cambiales el tamaño, giralos y acomodalos donde quieras.
        </p>
        <Row xs={{ cols: 4 }} sm={{ cols: 6 }} md={{ cols: 8 }}>
          <Stickers setStickers={setStickers} canvasWidth={dimensions.width} canvasHeight={dimensions.height} />
        </Row>
      </Container>
      <Container as="footer">
        <Row as="footer">
          <Col xs={{ span: 12 }}>
            <p>
              Subí tu foto, intervenila y compartila en redes sociales con las etiquetas
            </p>
            <p className="hashtags">
              #MemoriaSí
              #SumemosPañuelos
              #48AñosDelGolpeGenocida
            </p>
            <p>
              🖤 Este 24 de marzo nos encontramos en las plazas de todo el país: digamos sí a la memoria y a todas las luchas por más derechos.
            </p>

            <Button
              className="copy-link"
              variant="light border-secondary"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(text);
              }}
            >copiar texto</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 12 }}>
            <p className="disclaimer">Tu privacidad importa, la página no almacena las fotos.</p>
          </Col>
        </Row>
        <Row>
          <p className="made-by" >
            Un aporte de <b>gcoop</b> a la campaña para abrazar la memoria de<br />
          </p>
          <p className="organizaciones">
            <a href="https://www.instagram.com/casanuestroshijos" rel="nofollow noreferrer noopener" target="_blank">
              Madres de Plaza de Mayo Linea Fundadora
            </a><span className="sep">•</span>
            <a href="https://www.instagram.com/abuelasdifusion" rel="nofollow noreferrer noopener" target="_blank">
              Abuelas de Plaza de Mayo
            </a><span className="sep">•</span>
            <a href="https://www.instagram.com/h.i.j.o.s._capital" rel="nofollow noreferrer noopener" target="_blank">
              H.I.J.O.S. Capital
            </a><span className="sep">•</span>
            <a href="https://www.instagram.com/familiares_cap" rel="nofollow noreferrer noopener" target="_blank">
              Familiares de Desaparecidos
            </a><span className="sep">•</span>
            <a href="https://www.instagram.com/cels_argentina" rel="nofollow noreferrer noopener" target="_blank">
              CELS
            </a>
          </p>
        </Row>
      </Container>
    </Container>
  );
}
